export const colours = {
  eerieBlack: "#191716",
  raisinBlack: "#292624",
  persimmon: "#EA5E12",
  orange: "#FC8224",
  xanthous: "#FCB023",
  sunglow: "#FFE8BF",
  persianIndigo: "#2E186A",
  midnightBlue: "#171F72",
  davysGray: "#4F4F50",
  battleshipGray: "#788585",
  silver: "#C9C9C9",
  offWhite: "#F1F2F3",
  white: "#FFFFFF",
};
