import { SvgIconProps } from "../types";

export const SvgIcon = ({ src, width, height, onClick }: SvgIconProps) => (
  <img
    style={onClick && { cursor: "pointer" }}
    src={`/img/svg/${src}`}
    alt={src}
    width={width}
    height={height}
    onClick={onClick}
  />
);
