const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
  hex = hex.replace(/^#/, "");

  if (hex.length !== 3 && hex.length !== 6) {
    return null;
  }

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((c) => c + c)
      .join("");
  }

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

const rgbToHex = (r: number, g: number, b: number): string | null => {
  if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
    return null;
  }

  const toHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export { hexToRgb, rgbToHex };
