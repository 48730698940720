const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/examples"],
    exact: true,
    component: "Examples",
  },
  {
    path: ["/coming-soon"],
    exact: true,
    component: "ComingSoon",
  },
];

export default routes;
